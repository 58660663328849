import PropTypes from "prop-types"
import React from "react"

import IconBookmark from "../Icons/IconBookmark"

import {
  Button,
  Menu,
  MenuButton,
  Box,
  MenuList,
  MenuItem,
  MenuGroup,
} from "@chakra-ui/react"

import { AnchorLink } from "gatsby-plugin-anchor-links"
import { cleanStringForAnchor } from "../../tools/helper"

const isCurrentSlide = (currentSlide, index) => currentSlide === index

const TOCParagraphButton = props => {
  return (
    <>
      <Menu>
        <MenuButton
          position="absolute"
          borderRadius="full"
          height="48px"
          width="30px"
          top={["-27px", "-27px", "53px", "53px"]}
          left={["26px", "26px", "-24px", "-24px"]}
          as={Button}
          colorScheme="buttonSecondary"
          aria-label="Table of Content"
        >
          <IconBookmark className="icon_mode" />
        </MenuButton>
        <MenuList
          sx={{
            "&:focus:not([data-focus-visible-added])": {
              shadow: "lg",
            },
            "&:focus:not(.focus-visible)": {
              shadow: "lg",
              boxShadow: "var(--chakra-shadows-lg) !important",
            },
          }}
          // colorScheme="buttonSecondary"
          borderRadius="20px"
          border="1px solid rgba(0,0,0,0.05)"
          shadow="lg"
        >
          <MenuGroup title={props.title}>
            {props.paragraphs?.map((p, index) => (
              <MenuItem
                // _hover={{ bg: "white" }}
                key={index}
                pl="24px"
                py="8px"
                maxWidth="375px"
                display={p?.textSectionTitel === null ? "none" : "block"}
                fontWeight={
                  isCurrentSlide(props.currentSlide, index) ? "bold" : ""
                }
              >
                <AnchorLink
                  title={p?.textSectionTitel}
                  key={index}
                  to={`${props.currentPath}#${cleanStringForAnchor(
                    p?.textSectionTitel
                  )}`}
                >
                  {`${p?.textSectionTitel}`}
                </AnchorLink>
              </MenuItem>
            ))}
          </MenuGroup>
        </MenuList>
      </Menu>
    </>
  )
}

TOCParagraphButton.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  direction: PropTypes.string,
}

TOCParagraphButton.defaultProps = {
  text: "Button",
  link: "/",
  onClick: () => {},
  primary: false,
  direction: "down",
}

export default TOCParagraphButton
